@font-face {
  font-family: "AnekLatin-Regular";
  /*  400 */
  src: url(./assets/fonts/aneklatin/AnekLatin-Regular.ttf);
}

@font-face {
  font-family: "AnekLatin-Medium";
  /*  500 */
  src: url(./assets/fonts/aneklatin/AnekLatin-Medium.ttf);
}

@font-face {
  font-family: "AnekLatin-SemiBold";
  /*  600 */
  src: url(./assets/fonts/aneklatin/AnekLatin-SemiBold.ttf);
}

@font-face {
  font-family: "AnekLatin-Bold";
  /*  700 */
  src: url(./assets/fonts/aneklatin/AnekLatin-Bold.ttf);
}

html,
body {
  margin: 0;
  font-family: "AnekLatin-Regular";
  /* -webkit-font-smoothing: antialiased; */
  /* -moz-osx-font-smoothing: grayscale; */
  /* font-style: normal; */
  font-size: 14px;
  line-height: 22px;
  height: 100%;
  overflow-wrap: break-word;
}

body,
code,
#root {
  font-family: "AnekLatin-Regular";
}